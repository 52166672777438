import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import './StoresPage.css';

const StoresPage = () => {
  return (
    <div className="terms">   
      <Header />
      <div className='container-fluid filter-container'>
        <div className="container">
          <div className='row'>
            <iframe title='map' src="https://www.google.com/maps/d/embed?mid=1MATG6qeZP9UyMi7-O_S1-9Z_0nVflAA&ehbc=2E312F" width="640" height="480"></iframe> 
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) 
}

export default StoresPage;